<big-ui-form-row
    [id]="settings.id"
    [label]="label()"
    [isRequired]="control?.isRequired"
    [errors]="control?.errors"
    [showError]="isInvalid"
    [showInfo]="!!settings.help"
    [infoTextTemplate]="help"
    (infoLayerOpened)="trackFormsInfoIconEvent($event)">
    <div class="grid flex-wrap grid-cols-12 gap-4">
        @for (child of settings.children; track $index) {
            @if (isStartItem(child.name) && child.attributes?.isNewDay === true) {
                <div
                    class="col-span-12">
                    {{ displayWeekday(child.attributes?.day) }}
                </div>
            }
            <div class="col-span-5">
                <ng-container
                    appElementHost
                    [id]="child.name" />
            </div>
            @if (isStartItem(child.name)) {
                <div class="col-span-2 mt-4 text-center">bis</div>
            }
        }
    </div>
</big-ui-form-row>

<ng-template #help>
    <div [innerHTML]="settings.help | nl2br"></div>
</ng-template>
